/**
 * This is used as content for the static Payment page
 */
import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import { navigate, Link } from "gatsby"
import { useFormik } from "formik"
import { useStripe } from "@stripe/react-stripe-js"

import Banner from "../../banner"
import Seo from "../../seo"
import Layout from "../../layout"

import createStripeCustomer from "../../../bssr-api/stripe/create-customer"
import createStripeSession from "../../../bssr-api/stripe/create-session"
import updateUserRecord from "../../../bssr-api/auth0/update-user"
import getUserRecord from "../../../bssr-api/auth0/get-user"

import PageLoader from "../../page-loader"
import Select from "../../inputs/select"

import { products } from "../../../utils/stripe"
import { isBrowser } from "../../../utils/auth"
import { TextField, LabelledInput } from "../../inputs"
import { Button } from "../../button"
import { useAuth } from "../../../providers/auth/AuthProvider"

function Payment({ token, sub }) {
  const [state, dispatch] = useAuth()
  const [membershipSelected, setMembershipSelected] = useState(false)
  const [loading, setLoading] = useState(true)
  const [submissionloading, setSubmissionLoading] = useState(false)
  const [requestError, setRequestError] = useState(false)
  const [initialValues, setInitialValues] = useState({
    hospital: {
      department: "",
      trust: "",
      site: "",
    },
  })

  const validationSchema = Yup.object().shape({
    planId: Yup.string().required("Please select a membership"),
    hospital: Yup.object().shape({
      department: Yup.string().required("This is a required field"),
      trust: Yup.string().required("This is a required field"),
      site: Yup.string().required("This is a required field"),
    }),
  })

  const stripe = useStripe()
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: {
      planId: "",
      ...initialValues,
    },

    onSubmit: async (values) => {
      if (!stripe) return

      setSubmissionLoading(true)

      if (requestError) {
        setRequestError(false)
      }

      if (isBrowser) {
        const profile = JSON.parse(localStorage.getItem("user"))
        const user = await getUserRecord(sub.uid, token)

        sessionStorage.setItem("user_id", user.data.user_id)

        try {
          const res = await updateUserRecord(
            sub.uid,
            {
              user_metadata: {
                hospital: values.hospital,
              },
            },
            false,
            token
          )

          if (res.data) {
            if (!user.data.app_metadata.stripe_id) {
              const customerInfo = {
                name: sub.name,
                email: sub.email,
              }

              // Create the customer
              const stripeCustomerRes = await createStripeCustomer(
                customerInfo,
                token
              )

              // If we have no customer...
              if (stripeCustomerRes.data.customer) {
                const sessionInfo = {
                  customer_id: stripeCustomerRes.data.customer.id,
                  plan_id: values.planId,
                }

                // Create stripe session...
                const stripeSessionRes = await createStripeSession(
                  sessionInfo,
                  token
                )

                // If there is a session...
                if (stripeSessionRes.data && stripeSessionRes.data.id) {
                  const redirect = await stripe.redirectToCheckout({
                    sessionId: stripeSessionRes.data.id,
                  })
                } else {
                  // No session show error...
                  console.log(
                    "there was an error creating the session",
                    stripeSessionRes
                  )
                  setSubmissionLoading(false)
                  setRequestError(true)
                }
              } else {
                // No customer show error...
                console.log("there was an error", stripeCustomerRes)
                setSubmissionLoading(false)
                setRequestError(true)
              }
            } else {
              // We have a stripe customer so create a session...
              const stripeSessionRes = await createStripeSession(
                {
                  customer_id: res.data.app_metadata.stripe_id,
                  plan_id: values.planId,
                },
                token
              )

              // If we have a session...
              if (stripeSessionRes.data && stripeSessionRes.data.id) {
                // Redirecti...
                const redirect = await stripe.redirectToCheckout({
                  sessionId: stripeSessionRes.data.id,
                })
                console.log("redirect", redirect)
              } else {
                console.log(
                  "there was an error creating the session",
                  stripeSessionRes
                )
                setSubmissionLoading(false)
                setRequestError(true)
              }
            }
          } else {
            console.log("There was a problem", res)
            setSubmissionLoading(false)
            setRequestError(true)
          }
        } catch (err) {
          console.log("err", err)
          setSubmissionLoading(false)
          setRequestError(true)
        }
      }
    },

    validationSchema,
  })

  const errors = formik.errors

  useEffect(() => {
    if (isBrowser) {
      const params = new URLSearchParams(document.location.search.substring(1))

      if (params.has("uid")) {
        const uid = params.get("uid")

        if (sub.uid !== `auth0|${uid}`) {
          if (isBrowser) {
            navigate("/account")
          }
        } else {
          // Get meta data for users, in the event that user was unable
          // to complete flow, we need to pre-populate fields.
          async function getUserData() {
            const user = await getUserRecord(sub.uid, token)

            if (user.data) {
              if (user.data.user_metadata.hospital) {
                setInitialValues({
                  hospital: user.data.user_metadata.hospital,
                })
              }
              setLoading(false)
            } else {
              alert(
                "We're sorry. There was a problem processing your request. Please try again."
              )
              navigate("/account")
            }
          }

          getUserData()
        }
      }
    }
  }, [])

  return (
    <Layout>
      <Seo title="Complete Your Membership" />
      <Banner label="Join the BSSR">Registration</Banner>

      {!loading ? (
        <div className="container mx-auto py-3 lg:py-12 px-5 lg:flex">
          {requestError ? (
            <div className="w-3/4">
              <p>
                There was a problem processing your request. Please return to
                your account and try again.
                <br />
                If the problem persists then please notify the web team by
                emailing{" "}
                <a href="mailto:web@bssr.org.uk" className="underline">
                  web@bssr.org.uk
                </a>
              </p>

              <Link
                to="/account"
                className="text-center mb-2 w-full hover:bg-indigo-800 inline rounded focus:bg-blue-400 text-white font-semibold bg-indigo-900 rounded-lg px-4 py-3"
              >
                Go back to your account
              </Link>
            </div>
          ) : (
            <form onSubmit={formik.handleSubmit} className="w-full">
              <h3 className="text-2xl font-bold mb-3">Account Details</h3>
              <fieldset name="personal-details">
                <div className="flex flex-wrap">
                  <div className="w-1/2 pr-2">
                    <label htmlFor="given_name" className="block text-gray-700">
                      Name
                    </label>
                    <TextField
                      id="given_name"
                      name="given_name"
                      type="text"
                      placeholder="Given name"
                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                      defaultValue={sub.name}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 pl-2">
                    <label htmlFor="email" className="block text-gray-700">
                      Email Address
                    </label>
                    <TextField
                      id="email"
                      name="email"
                      type="email"
                      placeholder="email"
                      className="w-full px-4 py-3 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                      defaultValue={sub.email}
                      disabled
                    />
                  </div>
                  <div className="w-1/2 pr-2">
                    <div className="my-4">
                      <label htmlFor="planId" className="block text-gray-700">
                        Membership
                        {errors.planId && (
                          <span className="text-red-600 font-bold inline-block mx-auto float-right">
                            {errors.planId}
                          </span>
                        )}
                      </label>
                      <Select
                        isError={errors.planId}
                        id="planId"
                        name="planId"
                        onChange={(e) => {
                          formik.handleChange(e)
                          setMembershipSelected(true)
                        }}
                        value={formik.values.planId}
                      >
                        <option>Please Select</option>

                        {products.map((product) => (
                          <option key={product.id} value={product.id}>
                            {product.description} (&pound;
                            {product.price.toFixed(2)})
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                </div>
              </fieldset>
              <h3 className="text-2xl font-bold mt-8 mb-3">
                Your Hospital Address
              </h3>
              <fieldset name="hospital-address">
                <div className="flex flex-wrap">
                  <div className="w-1/2 pr-2">
                    <LabelledInput
                      label="Department*"
                      id="hospital.department"
                      name="hospital.department"
                      value={formik.values.hospital.department}
                      error={errors.hospital && errors.hospital.department}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-1/2 pl-2">
                    <LabelledInput
                      label="Trust*"
                      id="hospital.trust"
                      name="hospital.trust"
                      value={formik.values.hospital.trust}
                      error={errors.hospital && errors.hospital.trust}
                      onChange={formik.handleChange}
                    />
                  </div>
                  <div className="w-1/2 pr-2">
                    <LabelledInput
                      label="Site*"
                      id="hospital.site"
                      name="hospital.site"
                      value={formik.values.hospital.site}
                      error={errors.hospital && errors.hospital.site}
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </fieldset>

              <div className="float-right">
                <Button
                  isLoading={submissionloading}
                  type="submit"
                  disabled={
                    !stripe ||
                    Object.keys(errors).length > 0 ||
                    !membershipSelected
                  }
                >
                  Proceed to Checkout
                </Button>
              </div>
            </form>
          )}
        </div>
      ) : (
        <PageLoader />
      )}
    </Layout>
  )
}

export default Payment
